<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card no-body>

      <b-row>

        <b-col
          sm="6"
          class="card-border"
        >
          <b-card-body>
            <!-- <div class="pricing-trial-content d-flex justify-content-between"> -->
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">
                {{ $t('crypto-deposit-title') }}
              </h3>
              <p>{{ $t('crypto-deposit-description', { currency: coin }) }}</p>
            </div>

            <!-- images -->
            <b-img
              fluid
              :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
              class="pricing-trial-img"
              alt="svg img"
            />
            <!--/ images -->
          </b-card-body>
        </b-col>
        <!-- </div> -->

        <b-col sm="6">
          <b-card-body>
            <b-alert
              class="p-2"
              show
              variant="danger"
            >
              <p>{{ $t('deposit-warning-dec', { currency: coin }) }}</p>
            </b-alert>
            <b-form class="mt-2">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label-for="coin"
                    :label="$t('coin')"
                  >
                    <b-form-input
                      id="coin"
                      v-model="coin"
                      readonly
                      name="coin"
                      :placeholder="$t('coin')"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label-for="network"
                    :label="$t('Network')"
                  >
                    <b-form-select
                      id="network"
                      v-model="network"
                      text-field="network.nick_name"
                      value-field="network.nick_name"
                      :options="coinInfo.currency_network"
                      :placeholder="$t('Network')"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="walletAddress"
                  cols="12"
                >
                  <b-form-group
                    id="popover-manual-1"
                    label-for="walletAddress"
                    :label="$t('walletAddress')"
                  >
                    <copy-to-clipboard
                      :text="walletAddress"
                      @copy="copyAddress"
                    >
                      <b-input-group class="mb-2">
                        <b-form-input
                          id="walletAddress"
                          v-model="walletAddress"
                          readonly
                          name="walletAddress"
                          :placeholder="$t('walletAddress')"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            icon="CopyIcon"
                            size="20"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </copy-to-clipboard>
                    <b-popover
                      target="popover-manual-1"
                      :show.sync="pop1"
                      triggers="click"
                      placement="bottom"
                    >
                      {{ $t('copied') }}
                    </b-popover>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
            <div
              class="mt-2 text-center mx-auto d-flex justify-content-center align-center"
            >
              <qrcode-vue
                v-if="walletAddress"
                :value="walletAddress"
                :background="isDarkTheme ? '#283046' : '#fff'"
                :foreground="isDarkTheme ? '#fff' : '#283046'"
                :size="size"
                class="qrcode"
              />
            </div>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      v-if="items.length !== 0"
      class="d-none d-sm-block"
      :title="$t('lastDeposits')"
    >
      <b-row align-h="center">
        <b-col cols="12">
          <b-table
            responsive
            striped
            hover
            sticky-header="1000px"
            :items="items"
            :fields="fields"
          >
            <template #cell(created_at)="data">
              <span>{{
                getFormattedDate(data.item["created_at"])

              }}
              </span>
            </template>
            <template #cell(status)="data">

              <b-button
                :variant="data.item['status'] === 'pending' ? 'warning' : data.item['status'] === 'success' ? 'success' : 'danger'"
                size="sm"
                style="display: block; width: 140px"
              > {{ data.item['status'] }}</b-button>

            </template>

            <template #cell(transaction_id)="data">
              <b-button
                variant="info "
                size="sm"
                style="display: block"
                :disabled="!data.item.transaction_id"
                @click="() => triggerTransactionModal(data.item)"
              >
                {{ $t('transaction_id') }}
              </b-button>
            </template>
          </b-table>
        </b-col>
        <b-col cols="auto">
          <b-pagination
            v-if="totalTransactions > perPageTransactions"
            v-model="currentpageTransactions"
            :total-rows="totalTransactions"
            :per-page="perPageTransactions"
            align="fill"
            size="sm"
            class="mt-2 mb-1"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-card
      v-if="items.length > 0"
      class="d-sm-none"
      :title="$t('lastDeposits')"
    >
      <b-row
        align-h="center"
      >
        <b-col cols="12">
          <div
            v-for="(item, index) in items"
            :key="index"
            :class="$store.state.appConfig.layout.skin === 'dark' ? 'card-bg' : 'transaction-card'"
            class="mb-2"
          >
            <div class="d-flex justify-content-between">
              <div>{{ $t('coin') }}</div>
              <h5>{{ item.currency }}</h5>

            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>{{ $t('Network') }}</div>
              <h5>{{ item.network }}</h5>

            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>{{ $t('amount') }}</div>
              <h5>{{ item.amount }}</h5>

            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>{{ $t('date') }}</div>
              <h5>{{ getFormattedDate(item.date) }}</h5>

            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>{{ $t('status') }}</div>
              <b-button
                :variant="item.status === 'pending' ? 'warning' : item.status === 'success' ? 'success' : 'danger'"
                size="sm"
                style="display: block; width: 140px"
              > {{ item.status }}</b-button>

            </div>
            <div class="d-flex justify-content-between mt-2">
              <b-button
                variant="info"
                size="sm"
                style="width:100%"
                :disabled="!item.transaction_id"
                @click="() => triggerTransactionModal(item)"
              >
                {{ $t('transaction_id') }}
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col cols="auto">
          <b-pagination
            v-if="totalTransactions > perPageTransactions"
            v-model="currentpageTransactions"
            :total-rows="totalTransactions"
            :per-page="perPageTransactions"
            align="fill"
            size="sm"
            class="mb-1"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      v-model="transactionIdModal"
      centered
      :title="$t('show_transaction_id')"
      ok-only
      :ok-title="$t('show_in_network')"
      no-close-on-backdrop
      :ok-disabled="!selectedTransactionNetwork"
      @ok="openTransaction"
    >
      <b-form-group
        id="popover-manual-2"
        label-for="transaction_id"
        :label="$t('transaction_id')"
      >
        <copy-to-clipboard
          :text="selectedTransactionId"
          @copy="copyTransaction"
        >
          <b-input-group class="mb-2">
            <b-form-input
              id="transaction_id"
              v-model="selectedTransactionId"
              readonly
              name="transaction_id"
              :placeholder="$t('transaction_id')"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="CopyIcon"
                size="20"
              />
            </b-input-group-append>
          </b-input-group>
        </copy-to-clipboard>
        <b-popover
          target="popover-manual-2"
          :show.sync="pop2"
          triggers="click"
          placement="bottom"
        >
          {{ $t('copied') }}
        </b-popover>
      </b-form-group>
    </b-modal>
  </b-overlay>
</template>
<script>
import {
  BOverlay, BForm, BFormGroup, BCard, BRow, BCol, BImg, BCardBody, BTable,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BPopover,
  BButton,
  BPagination,
  BModal,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import CopyToClipboard from 'vue-copy-to-clipboard'
import QrcodeVue from 'qrcode.vue'
import moment from '@/libs/moment'
import CustomerWalletApis from '@/modules/crypto/services/apis/customer-wallet'

const customerWallet = new CustomerWalletApis()

export default {
  components: {
    BOverlay,
    BForm,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BImg,
    BCardBody,
    BFormInput,
    CopyToClipboard,
    BInputGroup,
    BInputGroupAppend,
    BPopover,
    QrcodeVue,
    BButton,
    BTable,
    BPagination,
    BModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      coin: '',
      network: '',
      coinInfo: {},
      walletAddress: '',
      pop1: false,
      lastDeposits: [],
      cards: [],
      currentpageTransactions: 1,
      totalTransactions: 0,
      perPageTransactions: 10,
      makeWalletLoading: false,
      size: 150,
      items: [],
      pop2: false,
      selectedTransactionId: '',
      selectedTransactionNetwork: '',
      transactionIdModal: false,
    }
  },

  computed: {
    fields() {
      return [
        {
          label: this.$t('coin'),
          key: 'currency',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('Network'),
          key: 'network',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('amount'),
          key: 'amount',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('date'),
          key: 'created_at',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('status'),
          key: 'status',
          tdClass: 'nameOfTheClass',
        },
        {
          label: '',
          key: 'transaction_id',
          tdClass: 'nameOfTheClass',
        },
      ]
    },
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin === 'dark'
    },

  },

  watch: {
    pop1(val) {
      if (val) {
        setTimeout(() => {
          this.pop1 = false
        }, 2500)
      }
    },
    currentpageTransactions(val) {
      if (val) {
        this.getlastDeposits()
      }
    },
    network(val) {
      this.show = true
      customerWallet.getWalletAddress({ currency: this.$route.params.coin_symbol, network: val }).then(async res => {
        const walletAddress = res.data.results?.address ? res.data.results?.address : res.data.results[0]?.network?.wallets[0]?.address
        if (walletAddress) {
          this.walletAddress = walletAddress
          this.show = false
        }
      }).catch(() => {
        this.walletAddress = ''
      }).finally(() => {
        this.show = false
      })
    },
  },

  async mounted() {
    if (this.$route.params.coin_name && this.$route.params.coin_symbol) {
      customerWallet.getCoin(this.$route.params.coin_symbol).then(coin => {
        this.coinInfo = coin.data.results
        this.network = coin.data.results.currency_network[0].network.nick_name
        this.coin = coin.data.results.token
      })
    } else {
      this.$router.push(`/${this.$i18n.locale}/crypto-wallets`)
    }
    this.getlastDeposits()
  },

  methods: {
    copyAddress() {
      this.pop1 = true
    },

    copyTransaction() {
      this.pop2 = true
    },

    getlastDeposits() {
      this.show = true
      customerWallet.lastDeposits({
        current_page: this.currentpageTransactions,
        per_page: this.perPageTransactions,
      })
        .then(res => {
          this.totalTransactions = res.data.results.paginate.total
          this.currentpageTransactions = res.data.results.paginate.current_page
          this.perPageTransactions = res.data.results.paginate.per_page
          this.items = res.data.results.data
        }).finally(() => {
          this.show = false
        })
    },

    getFormattedDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    triggerTransactionModal({ transaction_id, network_name }) {
      this.transactionIdModal = true
      // eslint-disable-next-line camelcase
      this.selectedTransactionId = transaction_id
      // eslint-disable-next-line camelcase
      this.selectedTransactionNetwork = network_name
    },
    openTransaction() {
      if (this.selectedTransactionNetwork === 'TRC20' && this.selectedTransactionId) {
        window.open(`https://tronscan.org/#/transaction/${this.selectedTransactionId}`, '_blank')
      } else if (this.selectedTransactionNetwork === 'BSC' && this.selectedTransactionId) {
        window.open(`https://bscscan.com/tx/${this.selectedTransactionId}`, '_blank')
      }
      this.transactionIdModal = false
    },
  },
}
</script>
<style lang="scss">
.card-border {
  border-right: 1px solid #e3e1e9;
}

.transaction-card {
  background-color: #efefef;
  padding: 15px;
  border-radius: 5px;
}

.card-bg {
  background-color: #172238;
  padding: 15px;
  border-radius: 5px;
}

.nameOfTheClass {
  padding: 20px !important;
}
</style>
<style scoped>
::v-deep .qrcode{
  width: 150px;
  height: 150px;
}
::v-deep .qrcode canvas{
  width: 100% !important;
}
</style>
